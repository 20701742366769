@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-item {
  @apply text-secondaryYellow;
  @apply px-3;
  @apply py-2;
  @apply rounded-md;
  @apply text-sm;
  @apply font-medium;
}

.nav-item :hover {
  @apply text-white;
}
